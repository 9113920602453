import Image from "next/image";
import styles from '../style/home/OurBrandPartners.module.css';
import brandPartner from '../../data/brandPartner.json';
import { CountryCode } from '../../lib/constants';
import imageLoader from "../../lib/helpers/imageLoader";
import { useGlobalContext } from "@/lib/context";


export function OurBrandPartners(props: { countryCode: CountryCode }) {
    const { props: { isDubaiPage, pageURL } } = useGlobalContext();
    const data = brandPartner?.filter(f => f.countryCode == props.countryCode) || [];
    const duplicatedData = [...data, ...data];
    return (
        <section className="section ourBrand">
            <div className={`${pageURL.includes('/landing-page') ? 'container1350' : isDubaiPage ? 'containerFull' : 'container'}`}>
                <h2 className="heading textCenter font26 text222 f500 mb12 fontSM20">Our Brand Partners</h2>
                <p className="subHeading textCenter mb46 font16 fontSM14">{pageURL.includes('/landing-page') ? "We Also Bring You The Best" : "An elite list of partners who strengthen our brand promise"}</p>
                <div className={styles.brandList}>
                    <div className={styles.marqueeWrapper}>
                        <div className={styles.marqueeGroup}>
                            {duplicatedData.map((d, idx) => (
                                <div key={idx} className={styles.sliderImage}>
                                    <Image
                                        src={d.relativePath}
                                        width={130}
                                        height={40}
                                        loader={imageLoader}
                                        alt={d.name + " - Interior Company Partner"}
                                        title={d.name}
                                        className="imgFluid"
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}